import React from "react";
import Layout from "../../../other/layout";
import ConceptionDeLogo from "../../../components/AC-StaticPages/Demarrez/ConceptionDeLogo/ConceptionDeLogo";
import SEO from "../../../other/seo";

export default function conceptionDeLogo() {
  return (
    <Layout>
      <SEO title="Conception de Logo" />
      <ConceptionDeLogo />
    </Layout>
  );
}
