import React from "react";
import { Link } from "gatsby";
import styles from "./styles.module.css";
import logo_samples from "../../../../assets/img/logo_samples.gif";
import CTAButton from "../../components/CTAButton";
import Button from "../../components/Button";

export default function ConceptionDeLogo() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1 style={{ marginBottom: "20px" }}>Conception de Logo</h1>
        <div className={styles.hero}>
          <h2 style={{ color: "black", fontSize: "2rem" }}>
            Trouvez le logo qui vous convient le miex.
          </h2>
          <p style={{ fontSize: "1rem" }}>
            Confiez-nous la tâche de concevoirum logo unique qui représente le
            mieux votre entreprise.
          </p>
          <CTAButton
            text={"Démarrer"}
            url={"/demarrez/conception-de-logo/forfaits-et-tarifs/"}
            top={"3px"}
          />
        </div>
      </div>
      <div className={styles.row}>
        <h2 style={{ margin: "30px 0 10px 0" }}>
          Un rappel visuel pour vos clients...
        </h2>
        <img
          className={styles.logos}
          src={logo_samples}
          alt="Bannière contenant les logos des clients"
        ></img>
        <h2 style={{ margin: "30px 0 10px 0" }}>Besoin d'un logo?</h2>
        <p>
          Notre talentueuse équipe de concepteurs de logo travaillera avec vous
          personnellement afin de créer ce logo unique qui fonctionnera pour
          votre entreprise. C'est à la fois facile et abordable !
        </p>
        <div className={styles.row}>
          <div className={styles.column}>
            <h3 style={{ margin: "30px 0 10px 0" }}>
              Forfait de conception de logo
            </h3>
            <p style={{ margin: "10px 0" }}>
              Donnez un élan à votre marqueet fidélisez votre clientèlepour la
              modique somme de 195 $.
            </p>
            <Button
              text={"Voir les forfaits"}
              url={"/demarrez/conception-de-logo/forfaits-et-tarifs/"}
            />
          </div>
          <div className={styles.column}>
            <h3 style={{ margin: "30px 0px 10px 0px" }}>
              Comment ça fonctionne
            </h3>
            <p style={{ margin: "10px 0" }}>
              Donnez le coup d’envoi à votre entre- prise avec un logo
              professionnel conçu en fonction de vos besoins, le tout à un prix
              avantageux.
            </p>
            <Button
              text={"Apprendre plus"}
              url={"/demarrez/conception-de-logo/comment-cela-fonctionne/"}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
}
